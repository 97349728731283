<template>
  <div>
    <b-row>

      <b-col
        v-if="reportData"
        md="12"
      >
        <training-list
          :data="reportData"
          title="Eğitim Listesi"
        /></b-col>
    </b-row>
    <div />
  </div></template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import trainingList from './TrainingList.vue'
import reportsStoreModule from '../reportsStoreModule'

export default {
  components: {
    trainingList,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reportData: null,

    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      store
        .dispatch('app-reports/fetchTrainingList', {})
        .then(response => {
          this.reportData = response.data.data
        })
        .catch(() => {
        })
    },

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const exampledata = ref(null)
    return {

      // Filter
      avatarText,
      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
