<template>
  <div v-if="data">

    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                {{ title }}
              </b-card-title>
              <b-card-sub-title />
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="questionTable.fields"
              :items="data"
              selectable
              responsive="sm"
              select-mode="single"
              @row-selected="historySelect"
            >
              <template #cell(user_answer_is_correct)="data">
                <b-badge
                  v-if="data.item.user_answer_id==0"
                  pill
                  variant="warning"
                >
                  Bu Soru Yanıtlanmadı
                </b-badge>
                <b-badge
                  v-else-if="data.value==true"
                  pill
                  variant="success"
                >
                  Doğru Cevaplandı
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="danger"
                >
                  Yanlış Cevaplandı
                </b-badge>

              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol, VBModal, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BBadge,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      questionTable: {
        fields: [
          { key: 'question', label: 'Soru' },
          // A regular column
          { key: 'correct_answer', label: 'Sorunun Doğru Yanıtı' },
          { key: 'user_answer', label: 'Kullanıcının Seçtiği Yanıt' },
          // A regular column
          { key: 'user_answer_is_correct', label: '' },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    historySelect(item) {
      // this.selectedTrainingData = item[0]
    },
  },
}
</script>
