<template>
  <div> <b-modal
          id="userHistoryModal"
          ref="userHistoryModal"
          title="Kullanıcı Eğitim Geçmişi"
          ok-only
          size="xl"
          ok-title="Ok"
        >
          <user-training-history
            v-if="selectedUserHistoryData"
            :data="selectedUserHistoryData"
            :title="selectedUserData.name+' adlı kişinin '+selectedTrainingData.name+' adlı eğitime ait sınav geçmişi'"
          />
        </b-modal>
    <b-row>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                {{ title }}
              </b-card-title>
              <b-card-sub-title>Eğitim üzerine çift tıklayarak verileri görebilirsiniz</b-card-sub-title>
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="trainingTable.fields"
              :items="data"
              selectable
              responsive="sm"
              select-mode="single"
              @row-selected="trainingSelect"
            >

              <!-- A custom formatted column -->
              <template #cell(time)="data">
                {{ data.value+' DK.' }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          v-if="selectedTrainingData"
          no-body
        >
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Eğitim Detayı
              </b-card-title>
              <b-card-sub-title>{{ selectedTrainingData.name }} - Kişi üstüne tıklayarak detayları görebilirsiniz</b-card-sub-title>
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="trainingUsersTable.fields"
              :items="trainingUserList"
              select-mode="single"
              selectable
              responsive="sm"
              @row-selected="userDetailSelect"
            >

              <!-- A custom formatted column -->
              <template #cell(name)="data">
                {{ data.item.name+' '+data.item.surname }}
              </template>
              <template #cell(history_count)="data">
                <b-badge
                  v-for="(h,index) in data.item.training_history"
                  :key="index"
                  pill
                  variant="secondary"
                >
                  {{ h.complete_stats.user_point }}/{{ h.complete_stats.total_point }}
                </b-badge>
              </template>
              <!-- A custom formatted column -->
              <template #cell(completed_history)="data">
                <b-badge
                  v-if="data.item.last_completed_history"
                  pill
                  :variant="data.item.last_completed_history.complete_stats.user_point<data.item.last_completed_history.complete_stats.total_point ?'warning':'success'"
                >
                  {{ data.item.last_completed_history.complete_stats.user_point }} Puan ({{ data.item.last_completed_history.complete_stats.total_point }} Gerekli Puan)
                </b-badge>

                <b-badge
                  v-else-if="data.item.training_history.length>0"
                  pill
                  variant="warning"
                >
                  {{ data.item.training_history.length }} Katılım
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="danger"
                >
                  Bu eğitime hiç katılmadı
                </b-badge>

              </template>
              <template #cell(status)="data">
                <b-progress

                  max="100"
                  :variant="data.item.color"
                  striped
                >
                  <b-progress-bar :value="data.value">
                    <strong>{{ data.value.toFixed(2) }} %</strong>
                  </b-progress-bar>

                </b-progress>
              </template>
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="data.item.color"
                >
                  {{ data.value }}
                </b-badge>
              </template>

              <!-- A virtual composite column -->
              <template #cell(sales)="data">
                {{ data.value+' '+data.item.currency_name }}
              </template>
              <template #cell(target)="data">
                {{ data.value+' '+data.item.currency_name }}
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BProgress, BProgressBar, BBadge, BRow, BCol, BModal, VBModal,
} from 'bootstrap-vue'
import store from '@/store'
import userTrainingHistory from './UserTrainingHistory.vue'

export default {
  components: {
    BCardHeader,
    BProgressBar,
    BCard,
    BCardBody,
    userTrainingHistory,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BModal,
    BProgress,
    BBadge,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      trainingTable: {
        fields: [
          { key: 'name', label: 'Eğitim Adı' },
          // A regular column
          { key: 'start_date', label: 'Başlangıç Zamanı' },
          { key: 'end_date', label: 'Bitiş Zamanı' },
          // A regular column
          { key: 'users_count', label: 'Tanımlanan Kişi Sayısı' },
          { key: 'started_user_count', label: 'Katılan Kişi Sayısı' },
          // A virtual column made up from two fields
          { key: 'time', label: 'Sınav Süresi' },
        ],
      },
      trainingUsersTable: {
        fields: [
          { key: 'name', label: 'Ad' },
          // A regular column
          { key: 'completed_history', label: 'Durum' },
          { key: 'success_rate', label: '' },
          { key: 'history_count', label: 'Puan Geçmişi' },
        ],
      },
      trainingUserList: null,
      selectedTrainingData: null,
      selectedUserData: null,
      selectedUserHistoryData: null,
    }
  },
  methods: {
    userDetailSelect(item) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedUserData = item[0]
      store
        .dispatch('app-reports/fetchTrainingUserHistoryDetail', { training_id: this.selectedTrainingData.id, user_id: this.selectedUserData.id })
        .then(response => {
          const { data } = response.data

          this.selectedUserHistoryData = data
          this.$refs.userHistoryModal.show()
        })
        .catch(() => {
        })
    },
    trainingSelect(item) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedTrainingData = item[0]
      // eslint-disable-next-line prefer-destructuring
      store
        .dispatch('app-reports/fetchTrainingUserReports', { training_id: item[0].id })
        .then(response => {
          const { data } = response.data
          this.trainingUserList = data
        })
        .catch(() => {
        })
    },
  },
}
</script>
